import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import dayjs from 'dayjs';

import usePrefix from 'utils/usePrefix';
import { MessagesIp } from 'utils/api/report';

import FlexDiv from 'components/FlexDiv';
import { ObjectReportWrapper, UsersList } from 'components/TableContainer/styles';
import EmptyList from 'components/EmptyList';
import NoResults from 'components/NoResults';
import Row from 'components/Row';

import { Content } from '../styles';
import { ReportType } from '../Criteria';

interface Props {
  objectData?: { reportType: ReportType, data: MessagesIp[] };
}

const MessagesIpReport: React.FC<Props> = ({objectData}) => {
  const t = usePrefix('Reports');

  return (
    <FlexDiv
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="flex-start"
      flexDirection="column"
      flex="1"
      width="100%"
    >
      <Content>
        <ObjectReportWrapper>
          {(!objectData?.data?.length) && (
            <EmptyList>
              <NoResults title={t('no_data')} />
            </EmptyList>
          )}
          {(!!objectData?.data?.length) && (
            <UsersList>
              <AutoSizer disableWidth>
                {({ height }: { height: number }) => (
                  <List
                    height={height}
                    width="100%"
                    itemCount={objectData.data.length}
                    itemSize={48}
                    innerElementType="ul"
                  >
                    {({ index, ...props }) => (
                      <Row
                        title={t(`${objectData.data[index].type}`)}
                        labels={[
                          t(`${objectData.data[index].type}`),
                          `${dayjs(new Date(objectData.data[index].send)).format('H:mm:ss:SSS')}, 
                          ${dayjs(new Date(objectData.data[index].send)).format('DD-MM-YYYY')}`,
                          `${objectData.data[index].ip ? objectData.data[index].ip : '0.0.0.0'}`,
                        ]}
                        {...props}
                      />
                    )}
                  </List>
                )}
              </AutoSizer>
            </UsersList>
          )}
        </ObjectReportWrapper>
      </Content>
    </FlexDiv>
  );
};

export default MessagesIpReport;
